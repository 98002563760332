import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Layout } from "../layout/Layout";
import Seo from "../components/common/Seo";
import ProductCard from "../components/common/ProductCard";

const WHITE = "white";

const CategoryPage = ({ pageContext }) => {
  const { slug, banner_text, banner_text_color, banner_image, banner_mobile_image, products } = pageContext.category;

  return (
    <Layout pageId="products-list" isHeaderTransparent={true} headerWhiteText={banner_text_color === WHITE}>
      <section className="relative">
        <GatsbyImage
          image={getImage(banner_mobile_image.imageFile)}
          alt={banner_mobile_image.title}
          className="h-auto w-full md:!hidden"
        />
        <GatsbyImage
          image={getImage(banner_image.imageFile)}
          alt={banner_image.title}
          className="!hidden h-auto w-full object-cover md:!block"
        />
        {banner_text && (
          <div
            className={`
              absolute left-0 top-1/2 z-10 w-full -translate-y-1/2 text-center [&>*]:mx-auto
              [&>h1]:text-[32px] [&>h1]:font-bold [&>h1]:leading-10 [&>h1]:md:text-6xl
              [&>p]:md:font-bold [&>p]:text-sm [&>p]:md:text-xl [&>p]:mt-3 [&>p]:md:mt-6 [&>p]:max-w-[50%]
              ${banner_text_color === WHITE ? "[&>*]:text-white" : "[&>*]:text-[#303030]"}
            `}
            dangerouslySetInnerHTML={{ __html: banner_text }}
          />
        )}
      </section>

      <section className="py-14 md:py-20">
        <div className="container">
          <div className="row">
            {products.map((product) => (
              <div className="md:col-6 lg:col-4" key={product.id}>
                <ProductCard name={product.name} image={product.sliders[0]} link={`/${slug}/${product.slug}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const Head = ({ pageContext }) => <Seo title={`${pageContext.category.name} | Hisense`} />;

export default CategoryPage;
